<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Program Owner Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-10">
        <div class="card">
          <place-holder v-if="loading"></place-holder>
          <div class="card-body">
            <form class="parsley-examples" v-if="!loading">
              <div class="row mb-3">
                <label for="program" class="form-label col-md-3">
                  Program Name
                </label>
                <div class="col-md-7">
                  <v-select @option:selected="getSelectedProgram" v-model="selectedProgram" label="name"
                  :selectable="(options) => isSelectableProgram(options)"
                    :options="programs" :clearable="false" :class="{
                      'p-invalid':
                        v$.selectedProgram.$error || errorFor('name'),
                    }"></v-select>
                  <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                    errors: v$.selectedProgram.$errors,
                    value: 'Program Name',
                  }"></v-errors>
                </div>
              </div>
              <div class="row mb-3">
                <label for="short_name" class="col-md-3 form-label">
                  Program Short Name
                </label>
                <div class="col-md-7">
                  <input type="text" id="short_name" name="short_name" class="form-control"
                    placeholder="Program Short Name" v-model="program_owner.short_name" disabled />
                </div>
              </div>
              <div class="row mb-3">
                <label for="trainer" class="form-label col-md-3">
                  Trainer Name <span class="text-danger">*</span>
                </label>
                <div class="col-md-7">
                  <template v-if="currentRouteName == 'program-owner-create'">
                    <v-select class="custom-selectCSS" @option:selected="getSelectedTrainer" 
                    v-model="selectdEmployee"
                       label="name" :options="trainers" 
                    :selectable="(options) => isSelectableTrainer(options)"
                    :clearable="false" :class="{
                        'p-invalid':
                          v$.selectdEmployee.$error || errorFor('name'),
                      }">
                    </v-select>
                    <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                      errors: v$.selectdEmployee.$errors,
                      value: 'Trainer Name',
                    }"></v-errors>
                  </template>
                  <template v-else>
                    <v-select disabled class="custom-selectCSS" 
                    v-model="selectdEmployee" id="trainer" label="name"
                      :options="trainers" :clearable="false">
                    </v-select>
                  </template>
                </div>
              </div>
              <div class="row mb-3">
                <label for="email" class="col-md-3 form-label"> Email </label>
                <div class="col-md-7">
                  <input type="text" id="email" name="email" class="form-control" placeholder="Email"
                    v-model="program_owner.email" disabled />
                </div>
              </div>
              <div class="row mb-3">
                <label for="phone" class="col-md-3 form-label">Phone</label>
                <div class="col-md-7">
                  <input type="text" id="phone" name="phone" class="form-control" placeholder="Phone Number"
                    v-model="program_owner.phone" disabled />
                </div>
              </div>
              <div class="row mb-3">
                <label for="position-level" class="col-md-3 form-label">Position Level</label>
                <div class="col-md-7">
                  <input type="text" name="position_level" placeholder="Position Level"
                    v-model="program_owner.position_level" class="form-control" disabled />
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'program-owners' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button :disabled="isLoading ? true : false" type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                        currentRouteName === 'program-owner-update'
                          ? updateProgramOwner()
                          : createProgramOwner()
                        ">
                      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      {{ isLoading == true ? 'Loading...' : 'Save' }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      program_owner: {
        name: "",
        email: "",
        phone: "",
        program_id: "",
        employee_id: "",
        program_name: "",
        short_name: "",
        user_id: "",
      },
      programs: [],
      trainers: [],
      filterEmployee: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      selectdEmployee: null,
      selectedProgram: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      url: null,
      loading: false,
      isLoading: false
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectdEmployee: { required },
      program_owner: {
        program_id: { required },
        program_name: { required },
        short_name: { required },
      },
    };
  },
  methods: {
    isSelectableProgram(options) {
      if(!this.selectedProgram) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableTrainer(options) {
      if(!this.selectdEmployee || options == undefined) return true;
      return this.selectdEmployee.id != options.id;
    },
    async getEmployees() {
      // this.loading = true;
      // await axios
      //   .get(`${this.baseUrlHRIS}api/employees`)
      //   .then((response) => {
      //     this.employees = response.data.data;
      //     if (this.currentRouteName == "program-owner-create") {
      //       this.loading = false;
      //     }
      //   })
      //   .catch(() => {
      //     this.toast.error("Not Found Employees!");
      //   });
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        // await this.getEmployee();
        this.loading = false;
      }
    },
    getTrainers() {
      axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          this.trainers = response.data.data.map(function (val) {
            //for active trainers
            if (val.status == 1) {
              return {
                id: val.user.id,
                employee_id: val.user.employee_id,
                name: val.user.name,
                email: val.user.email,
              };
            }
          });
        })
        .catch(() => {
          this.toast.error("Not Found Trainers!");
        });
    },
    getSelectedTrainer() {
      if (this.selectdEmployee) {
        this.filterEmployee = this.employees.filter((employee) => {
          if (employee.emp_id == this.selectdEmployee.employee_id) {
            return employee;
          }
        });
        this.program_owner.user_id = this.filterEmployee[0].id;
        this.program_owner.employee_id = this.filterEmployee[0].emp_id;
        this.program_owner.email = this.filterEmployee[0].email;
        this.program_owner.name = this.filterEmployee[0].name;
        this.program_owner.phone = this.filterEmployee[0].phone_no;
        this.program_owner.position_level =
          this.filterEmployee[0].position_level[0].name;
      }
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.program_owner.program_id = this.selectedProgram.id;
        this.program_owner.short_name = this.selectedProgram.short_name;
        this.program_owner.program_name = this.selectedProgram.name;
      }
    },
    getPrograms() {
      axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },

    async createProgramOwner() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let form = new FormData();
      form.append("program_id", this.program_owner.program_id);
      form.append("program_name", this.program_owner.program_name);
      form.append("short_name", this.program_owner.short_name);
      form.append("user_id", this.program_owner.user_id);
      form.append("employee_id", this.program_owner.employee_id);

      this.isLoading = true;
      axios
        .post(`${this.baseUrl}admin/v1/program-owners`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$router.push({ name: "program-owners" });
          this.toast.success("Successfully Program Owner Created!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          if (this.errors) {
            this.toast.error(this.errors.id[0] || this.errors.error);
          } else {
            this.toast.error("Something Went Wrong!");
          }
          this.isLoading = false;
        });
    },

    async getProgramOwnerById() {
      this.loading = true;
      await this.getEmployees();
      axios
        .get(`${this.baseUrl}admin/v1/program-owners/${this.$route.params.id}`)
        .then((response) => {
          const result = response.data.data;
          this.selectedProgram = {
            id: result.program_id,
            name: result.program_name,
            short_name: result.program_short_name,
          };

          this.selectdEmployee = {
            id: result.user_id,
            employee_id: result.employee_id,
            name: result.trainer.user.name,
          };

          this.getSelectedProgram();
          this.getSelectedTrainer();
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program Owner!");
        });
    },
    async updateProgramOwner() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let updateForm = new FormData();
      updateForm.append("program_id", this.program_owner.program_id);
      updateForm.append("program_name", this.program_owner.program_name);
      updateForm.append("short_name", this.program_owner.short_name);
      updateForm.append("user_id", this.program_owner.user_id);
      updateForm.append("employee_id", this.program_owner.employee_id);
      updateForm.append("_method", "put");

      this.isLoading = true;
      axios
        .post(
          `${this.baseUrl}admin/v1/program-owners/${this.$route.params.id}`,
          updateForm,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "program-owners" });
          this.toast.success("Successfully Program Owner Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          if (this.errors) {
            this.toast.error(this.errors.id[0] || this.errors.error);
          } else {
            this.toast.error("Something Went Wrong!");
          }
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.currentRouteName == "program-owner-update") {
      this.getProgramOwnerById();
    } else {
      this.getEmployees();
    }
    this.getTrainers();
    this.getPrograms();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    vSelect,
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}

.img-upload-input {
  display: none;
  z-index: -9999;
}

.img-upload-icon {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.drop,
.preview {
  min-height: 220px;
  border: 2px dashed #d9d9d9;
  width: 100%;
  height: auto;
  margin: auto;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  /* background-image: linear-gradient(#71d5e3, #dcebe5) !important; */
}

.preview {
  position: absolute;
  top: 0;
  border: unset;
}

.preview img {
  max-width: 100%;
  max-height: 215px;
}

#remove {
  position: absolute;
  top: 465px;
  right: 513px;
  cursor: pointer !important;
  z-index: 5;
  text-align: center !important;
  font-size: 1em;
  width: 1.8em;
  height: 1.8em;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  box-shadow: 0 0 0 0 hsl(0deg 0% 100% / 0%);
  transition: box-shadow 0.25s ease-in;
}

#remove:hover {
  box-shadow: 0 0 0 0.21em hsl(0deg 0% 100% / 90%) !important;
}

#remove>.icon-dual {
  color: #fff !important;
  font-size: 1em;
}
</style>


